import React from 'react'
import styled from '@emotion/styled'

const Failed = (): JSX.Element => {
  return (
    <PlanDetailsStyle>
      <span className="icon icon-info mr-2 text-lively-magenta"></span>
      <h1>Let’s fix this.</h1>
      <p>
        We’re sorry, we’ve encountered an issue and we could not sign you up
        online. Please call our Customer Care team at{' '}
        <span>1-800-359-0476.</span>
      </p>
      <button type="submit">
        <a href="tel:1-800-359-0476">Call Customer Care</a>
      </button>
    </PlanDetailsStyle>
  )
}

export default Failed

const PlanDetailsStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 60vh;
  justify-content: center;
  .icon {
    font-size: 43px;
  }
  h1 {
    margin: 1rem 0;
  }
  span {
    color: #e60046;
  }
  a,
  a:hover,
  a:active {
    color: #fff;
    text-decoration: none;
  }
  button {
    background: linear-gradient(46.91deg, #e60046 0%, #ce1578 117.6%);
    border-radius: 6px;
    height: 48px;
    font-size: 18px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #ffffff;
    width: 184px;
  }
`
